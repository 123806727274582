@import url("https://fonts.googleapis.com/css?family=Poppins:400,700");
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");

/* https://reactgo.com/add-fonts-to-react-app/ */
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BadScript-Regular";
  src: local("BadScript-Regular"), url(./fonts/badscript/BadScript-Regular.ttf) format("truetype");
}

:root {
  /* base color behind everything */
  --background: #282e38;

  /* seperates the screen from the base color */

  --body: #353d4a;

  /* dark logo */
  --logo: #ffffff;
  --logoOutline: #282e38;
  --registrationLogo: #ffffff;

  /* Menu colors */
  --navbar: #353d4a;

  --navIcon: #ffffff;
  --navIconHover: #faaf40;
  --navLink: #467bb0;
  --navLinkHover: #ffffff;
  --wheel: #faaf40;
  --wheelIcon: #ffffff;

  /* Selected */
  --highlight: #faaf40;
  --highlightHover: #f99c10;
  --selected: #faaf40;
  --selectedHover: #faaf40;

  /* Progress Bar during event */
  --progressBar: #ffffff;
  --progressText: #2f3640;
  --progressSpinner: #ffffff;

  /* Modal color behind everything */
  --modal: #38404c;

  /* wraps around to group fields together */
  --content-divider: #2f3640;
  --tab: #303843;
  --tabSelected: #353d4a;
  --tabText: #ffffff;
  --tabBorder: #2d353f;

  /* INPUT FIELDS */
  /* text colors for text*/
  --text: #acb4bd;
  --text-dark: #000000;
  --placeholderText: #acb4bd;
  --incoming: #abb5c4;
  --outgoing: #467bb0;

  --silver: #acb4bd;
  --switchText: #acb4bd;
  --defaultBorderColor: #acb4bd;
  --defaultBackgroundBorderColor: #282e38;
  --defaultBodyBorderColor: #282e38;
  --checkboxBorderColor: #65676a;

  /* Input field color is the same as base color  */
  --container: #282e38;
  --input-background: #282e38;
  --toggle-wrapper: #282e38;
  --headerText: #ffffff;
  --white: #ffffff;
  --labelColor: #ffffff;
  --buttonText: #ffffff;
  --blackButtonText: #ffffff;

  /* Input field extra colors */
  --border-color-dirty: #a21924;

  /* error text */
  --error-text: #ffffff;

  /* List item display */
  --defaultPopupColor: #acb4bd;
  --list-items-container: #222730;

  /* Banner Colors */
  --bannerInfoText: #363d4a;
  --bannerInfoBackground: #faaf40;
  --bannerEmergencyText: #a21924;
  --bannerEmergencyBackground: #faaf40;

  /* Text colors for buttons */

  /* Blue buttons: */
  --blue-button: #467bb0;
  --blue-button-hover: #5f8fbf;

  /* Green buttons: */
  --green-button: #619050;
  --green-button-hover: #61a348;

  /* Red buttons: */
  --red-button: #905142;
  --red-button-hover: #b94d33;
  --mexican-red: #a21924;

  /* Orange buttons */
  --orange: #faaf40;
  --brown-button: #bf894b;
  --brown-button-hover: #e2943a;

  /* Black buttons */
  --black-button: #1d222b;
  --black-button-hover: #e2943a;

  /* Search and toggle backgrounds */
  --search-background: #1d222b;
  --toggle-background: #323a49;

  /* Status badges */
  --status-badge-archived: #435c79;
  --status-badge-draft: #5f666e;
  --status-badge-published: #61a348;
  --status-badge-backordered: #5f666e;
  --status-badge-cancelled-return: #905142;
  --status-badge-cancelled: #905142;
  --status-badge-contacted: #467bb0;

  --status-badge-authapproved: #61a348;
  --status-badge-authcancelled: #905142;
  --status-badge-authdeclined: #905142;
  --status-badge-authfailed: #905142;
  --status-badge-authnew: #cfa677;
  --status-badge-authpartialapproval: #61a348;
  --status-badge-authpending: #cfa677;

  --status-badge-diagnosed: #5f666e;
  --status-badge-fullyreceived: #61a348;
  --status-badge-invoiced: #61a348;
  --status-badge-maast-customer: #467bb0;
  --status-badge-new: #4a4f55;
  --status-badge-onbench: #bf894b;
  --status-badge-onhold: #5f666e;
  --status-badge-open: #cfa677;
  --status-badge-ordered: #435c79;
  --status-badge-paid-in-full: #61a348;
  --status-badge-paidrefund: #4b8038;
  --status-badge-partiallyordered: #6182a8;
  --status-badge-partiallyreceived: #9d6e5a;
  --status-badge-partiallyrefunded: #325525;
  --status-badge-partiallyshipped: #9d6e5a;
  --status-badge-partialpayment: #9d6e5a;
  --status-badge-partsordered: #435c79;
  --status-badge-pending: #cfa677;
  --status-badge-pickedup: #61a348;
  --status-badge-pre-matched: #5f666e;
  --status-badge-processed-created: #61a348;
  --status-badge-processed-matched: #325525;
  --status-badge-processed: #7b97b7;
  --status-badge-purchase-created: #7b97b7;
  --status-badge-received: #bf894b;
  --status-badge-refunded: #325525;
  --status-badge-sent: #61a348;
  --status-badge-shipped: #325525;
  --status-badge-sub-active: #61a348;
  --status-badge-sub-cancelled: #5f666e;
  --status-badge-sub-complete: #42474c;
  --status-badge-sub-paused: #bf894b;
  --status-badge-sub-suspended: #905142;
  --status-badge-unshipped: #cfa677;
  --status-badge-vaulted: #bf894b;

  /*Light version*/
  --status-badge-sub-active-light: rgba(97, 163, 72, 0.25);
  --status-badge-sub-suspended-light: #905142;
  --status-badge-sub-cancelled-light: #5f666e;
  --status-badge-sub-paused-light: rgba(191, 137, 75, 0.25);
  --status-badge-sub-complete-light: #42474c;

  /* Font-size Defaults */
  --font-size-mobile-default: 3.7vw;
  --font-size-mobile-button: 0.9em;

  --font-size-tablet-default: 3vw;
  --font-size-tablet-icon: 5vw;
  --font-size-tablet-toggle: 2vw;

  --font-size-desktop-header: 1.35vw;
  --font-size-desktop-default: 0.9vw;
  --font-size-desktop-button: 0.9vw;

  --font-size-clock: 3em;
  --font-size-icon: 8vw;
  --font-size-wheel-icon: 0.1em;

  --fontSizeHeadingLarge: 2.5em;
  --fontSizeHeading: 2em;
  --fontSizeHeadingMedium: 1.75em;
  --fontSizeHeadingSmall: 1.5em;
  --font-size-larger: 1.4em;
  --font-size-large: 1.2em;
  --font-size-medium: 1em;

  --font-size-small: 0.85em;
  --font-size-xsmall: 0.75em;
  --font-size-xxsmall: 0.6em;
  --font-size-xxxsmall: 0.4em;

  --fontPrintHeading: 24px;
  --fontPrint: 12px;
  --fontPrintSmall: 10px;

  --fontUPC: 20px;

  --border-radius-small: 0.15em;
  --border-radius: 0.4em;
  --border-radius-large: 1em;
  --border-radius-extralarge: 8em;
  --border-radius-round: 50%;

  --font-family-poppins: "Poppins", Helvetica;
}

body.light {
  /* base color behind everything */
  --background: #f9fafb;

  /* seperates the screen from the base color */
  --body: #efeff1;

  /* light logo */
  --logo: #ffffff;
  --logoOutline: #707070;
  --registrationLogo: #707070;

  /* Menu colors */
  --navbar: #efeff1;
  --navIcon: #707070;
  --navIconHover: #7192b9;
  --navLink: #467bb0;
  --navLinkHover: #ffffff;
  --wheel: #467bb0;
  --wheelIcon: #707070;

  /* Selected */
  --highlight: #d88373;
  --highlightHover: #c95740;
  --selected: #d88373;
  --selectedHover: #c95740;

  /* Progress Bar */
  --progressBar: #7192b9;
  --progressText: #ffffff;
  --progressSpinner: #707070;

  /* Modal (Popup Modal) color behind everything */
  --modal: #e9e9ec;

  /* wraps around to group fields together */
  --content-divider: #f7f7f7;

  --tab: #efeff1;
  --tabSelected: #efeff1;
  --tabText: #707070;
  --tabBorder: #ffffff;

  /* INPUT FIELDS */
  /* text colors for text*/
  --text: #707070;
  --text-dark: #000000;
  --placeholderText: #333333;
  --incoming: #abb5c4;
  --outgoing: #467bb0;

  --silver: #ffffff;
  --switchText: #ffffff;
  --defaultBorderColor: #acb4bd;
  --defaultBackgroundBorderColor: #dedee3;
  --defaultBodyBorderColor: #dedee3;

  /* WHITE: */
  /* Input field color is the same as base color  */
  --container: #ffffff;
  --input-background: #ffffff;
  --toggle-wrapper: #ffffff;
  --buttonText: #ffffff;
  --white: #ffffff;
  --labelColor: #474747;
  --headerText: #707070;
  --blackButtonText: #474747;

  /* Input field extra colors */
  --border-color-dirty: #a21924;

  /* error text */
  --error-text: #000000;

  /* List item display */
  --defaultPopupColor: #dedee3;
  --list-items-container: #222730;

  /* Banner Colors */
  --bannerInfoText: #363d4a;
  --bannerInfoBackground: #d88373;
  --bannerEmergencyText: #ffffff;
  --bannerEmergencyBackground: #a21924;

  /* Text colors for buttons */

  /* Blue buttons: */
  --blue-button: #7192b9;
  --blue-button-hover: #9ebfe5;

  /* Green buttons: */
  --green-button: #7eb56a;
  --green-button-hover: #79c35c;

  /* Red buttons: */
  --red-button: #c76a54;
  --red-button-hover: #dd7860;
  --mexican-red: #a21924;

  /* Orange buttons */
  --orange: #7192b9;
  --brown-button: #707070;
  --brown-button-hover: #9d9d9d;

  /* Black buttons */
  --black-button: #1d222b;
  --black-button-hover: #e2943a;

  /* Search and toggle backgrounds */
  --search-background: #ffffff;
  --toggle-background: #707070;

  /* Status badges */
  --status-badge-backordered: #5f666e;
  --status-badge-cancelled: #905142;
  --status-badge-cancelled-return: #905142;
  --status-badge-diagnosed: #7192b9;
  --status-badge-fullyreceived: #61a348;
  --status-badge-invoiced: #61a348;
  --status-badge-maast-customer: #467bb0;
  --status-badge-onbench: #61a348;
  --status-badge-onhold: #e19a47;
  --status-badge-open: #707070;
  --status-badge-ordered: #435c79;
  --status-badge-paid-in-full: #61a348;
  --status-badge-paidrefund: #538e3e;
  --status-badge-refunded: #538e3e;
  --status-badge-partialpayment: #9d6e5a;
  --status-badge-partiallyordered: #6182a8;
  --status-badge-partiallyreceived: #9d6e5a;
  --status-badge-partiallyrefunded: #437132;
  --status-badge-partsordered: #435c79;
  --status-badge-purchase-created: #7b97b7;
  --status-badge-processed: #7b97b7;
  --status-badge-received: #bf894b;
  --status-badge-sent: #61a348;
  --status-badge-sub-active: #61a348;
  --status-badge-sub-suspended: #905142;
  --status-badge-sub-cancelled: #5f666e;
  /* --status-badge-sub-paused: #bf894b; */
  --status-badge-sub-paused: #bf894b;
  --status-badge-sub-complete: #5f666e;
  --status-badge-vaulted: #bf894b;

  /*Light version*/
  --status-badge-sub-active-light: rgba(97, 163, 72, 0.25);
  --status-badge-sub-suspended-light: #905142;
  --status-badge-sub-cancelled-light: #5f666e;
  --status-badge-sub-paused-light: rgba(191, 137, 75, 0.25);
  --status-badge-sub-complete-light: #5f666e;
}
