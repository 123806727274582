.switch-wrapper {
  padding: 0.3em;
  background-color: var(--toggle-wrapper);
  border-radius: var(--border-radius);
  margin-bottom: 0.5em;
  display: grid;
  grid-template-columns: auto auto;
}
.switch {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 1.75em;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--search-background);
  transition: 0.2s;
  display: grid;
  grid-template-columns: auto auto;
  padding-top: 0.18em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-family: var(--font-family-poppins);
}
.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  left: 0.4em;
  bottom: 0.4em;
  background-color: var(--container);
  transition: 0.2s;
}
input:not(:checked) + .slider,
input:checked + .slider {
  background-color: var(--toggle-background);
}
.yes,
.no {
  color: var(--switchText);
}
input + .slider > .yes {
  visibility: hidden;
}
input:checked + .slider > .no {
  visibility: hidden;
}
input:checked + .slider > .yes {
  visibility: visible;
}
input + .slider > .no {
  visibility: visible;
}
input:focus + .slider {
  box-shadow: 0 0 0.2em var(--defaultBorderColor);
}
input + .slider:before {
  background-color: var(--mexican-red);
}
input:checked + .slider:before {
  transform: translateX(2.1em);
  background-color: var(--green-button);
}
/* Rounded sliders */
.slider.round {
  border-radius: 1em;
}
.slider.round:before {
  border-radius: var(--border-radius-round);
}
.toggle-label {
  margin: auto 0;
}
.toggle-switch {
  text-align: right;
  font-size: var(--font-size-small);
  margin-left: 1em;
}
.save-disabled:hover,
.save-disabled .slider:hover {
  cursor: not-allowed;
}
