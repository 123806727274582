@media print and (min-width: 110mm) {
  body {
    font-size: var(--fontPrint);
  }
  .campaignSwitchesHr,
  .noPage {
    display: none;
  }

  .areaOrderNumber {
    font-size: var(--fontSizeHeadingLarge);
  }

  /********** List Screens *********/
  #list-container {
    margin: 1.5em;
  }
  #list-container .mobile {
    display: none;
  }
  #list-container .desktop {
    display: block;
  }
  #list-container .list-grid {
    display: grid;
    column-gap: 0.75em;
    overflow-wrap: break-word;
    padding: 0;
  }
  #list-container .list-item,
  #list-container .header {
    padding-left: 0;
    padding-right: 0;
  }
  #list-container .expander {
    display: none;
  }

  .reportStartDate input {
    border: none;
  }

  /********** Customer List *********/
  .customerlist .list-grid {
    grid-template-columns: repeat(5, auto);
  }
  .customerlist .list-header-caret {
    grid-column: 1 / span 1;
  }

  .customerlist.prospectlist .list-grid {
    grid-template-columns: repeat(6, auto);
  }

  /********** Product List *********/
  .productlist .list-grid {
    grid-template-columns: repeat(8, auto);
  }
  .productlist .list-header1 {
    grid-column: 1 / span 1;
  }

  /********** Supplier List *********/
  .supplierlist .list-grid {
    grid-template-columns: repeat(5, auto);
  }
  .supplierlist .list-header1 {
    grid-column: 1 / span 1;
  }

  /********** Invoice List *********/
  .invoicelist.all .list-grid,
  .invoicelist.open .list-grid,
  .invoicelist.processed .list-grid {
    grid-template-columns: repeat(6, auto);
  }
  .invoicelist.onlineunshipped .list-grid {
    grid-template-columns: repeat(8, auto);
  }

  /********** Order List *********/
  .orderlist.open .list-grid {
    grid-template-columns: repeat(8, auto);
  }

  .orderlist.all .list-grid,
  .orderlist.processed .list-grid {
    grid-template-columns: repeat(9, auto);
  }

  /********** Repair List *********/
  .repairlist.all .list-grid,
  .repairlist.open .list-grid {
    grid-template-columns: repeat(9, auto);
  }
  .repairlist.processed .list-grid {
    grid-template-columns: repeat(8, auto);
  }

  /********** Report List *********/
  .reportlist .list-grid {
    grid-template-columns: repeat(2, auto);
  }

  /********** Quote List *********/
  .quotelist.open .list-grid {
    grid-template-columns: repeat(6, auto);
  }

  .quotelist.all .list-grid,
  .quotelist.processed .list-grid {
    grid-template-columns: repeat(7, auto);
  }

  /********** Purchase List *********/
  .purchaselist.open .list-grid {
    grid-template-columns: repeat(5, auto);
  }
  .purchaselist.all .list-grid,
  .purchaselist.processed .list-grid {
    grid-template-columns: repeat(6, auto);
  }

  /********** Message List *********/
  .messagelist .list-grid {
    grid-template-columns: 100%;
  }
  /* .messagelist .list-grid .panel_left {
    grid-template-columns: max-content 1fr;
    align-content: start;
  } */
  /********** Timesheet List *********/
  .timesheetlist .list-grid {
    grid-template-columns: 7em 1fr 10em 10em 10em 1em;
  }

  .printedTimesheetNote {
    margin-left: 7.75em;
  }

  /********** Billing Subscriptions List *********/
  .billinglist .list-grid {
    grid-template-columns: 1fr max-content 2fr repeat(4, max-content);
    row-gap: 1em;
  }
  .billinglist.plans .list-grid {
    grid-template-columns: max-content 1fr repeat(4, max-content);
  }

  /********** Recurring Subscriptions List *********/
  .recurringlist.owing .list-grid,
  .recurringlist.inactive .list-grid,
  .recurringlist.active .list-grid {
    grid-template-columns: max-content 1fr 15% 1fr max-content 11% 11% 11%;
    row-gap: 1em;
  }
  .recurringlist.plans .list-grid {
    grid-template-columns: repeat(4, 1fr) max-content;
  }

  .recurringlist.invoices .list-grid {
    grid-template-columns: repeat(3, max-content) 1fr max-content max-content;
  }

  .recurringlist.cron .list-grid {
    grid-template-columns: repeat(11, auto);
    row-gap: 1em;
  }

  /********** Campaign List *********/
  .campaignlist .list-grid {
    grid-template-columns: max-content 1fr max-content max-content;
  }

  /********** Detail Screens *********/

  .clientDetail {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-small);
  }
  .areaClientLogo img {
    height: 130px;
    width: auto;
  }
  .areaOrderNumber {
    margin-bottom: 0;
  }
  .totalcontainer {
    grid-template-columns: auto max-content 35%;
  }
  .areaBarCode {
    display: block;
    text-align: right;
  }
  .areaStoreCredit,
  .areaPONumberLabel,
  .areaProductUPCBarcode,
  .areaTrackingNumberLabel {
    text-align: right;
    font-weight: normal;
  }
  .areaSalesperson {
    margin-bottom: 0;
  }

  .trackingNumberLabel,
  .poNumberLabel {
    font-weight: normal;
  }

  .header,
  .list-item {
    padding: 0.5em 1em 0.2em 0.12em;
  }
  .areaHorizontalRule {
    width: 100%;
  }
  .headerRule4 {
    display: block;
    width: 100%;
    grid-column: 1 / span 4;
  }

  .headerRule5 {
    display: block;
    width: 100%;
    grid-column: 1 / span 5;
  }
  .headerRule6 {
    display: block;
    width: 100%;
    grid-column: 1 / span 6;
  }

  .headerRule7 {
    display: block;
    width: 100%;
    grid-column: 1 / span 7;
  }

  .headerRule8 {
    display: block;
    width: 100%;
    grid-column: 1 / span 8;
  }

  .headerRule9 {
    display: block;
    width: 100%;
    grid-column: 1 / span 9;
  }

  .headerRule10 {
    display: block;
    width: 100%;
    grid-column: 1 / span 10;
  }

  .headerRule11 {
    display: block;
    width: 100%;
    grid-column: 1 / span 11;
  }

  .areaInputItem input,
  .areaInputItem textarea,
  .list-item .areaInputItem input {
    padding: 0;
    margin: 0;
    border: 0;
  }

  .toporderbarcode,
  .receiptbarcode {
    display: grid;
  }

  .toporderbarcode svg {
    margin-left: auto;
  }

  .bottomorderbarcode {
    display: none;
  }

  .receiptbarcode svg {
    margin: auto;
  }
  .areaClientEmail,
  .receiptfooter {
    display: none;
  }

  .areaAgreementDate {
    margin-top: 0;
  }
  .areaCampaignCard {
    margin-bottom: 0;
  }

  .areaCampaignLink {
    text-align: left;
  }

  .statusbadge.Pending,
  .areaIdPanel {
    text-align: right;
  }

  /********** Customer Detail *********/

  .areaStoreCredit {
    text-align: right;
    margin: 0;
  }
  .areaBaseDetailContainer.areaDetailCustomer {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck";
  }

  .areaBaseDetailContainer.areaDetailCustomer.tabprospects {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaProspectAdditionalData areaProspectAdditionalData areaProspectAdditionalData areaProspectAdditionalData areaProspectAdditionalData"
      "areaProspectAgreement areaProspectAgreement areaProspectAgreement areaProspectAgreement areaProspectAgreement";
  }

  .areaDetailCampaign {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaSwitches areaSwitches areaSwitches areaSwitches areaSwitches"
      "AreaSwitchesCampaign AreaSwitchesCampaign AreaSwitchesCampaign AreaSwitchesCampaign AreaSwitchesCampaign"
      "areaCampaignDetails areaCampaignDetails areaCampaignDetails areaCampaignDetails areaCampaignDetails"
      "areaCampaignLink areaCampaignLink areaCampaignLink areaCampaignLink areaCampaignLink";
  }

  /* We do not print the supplier's shipping address on the Purchase Print Page */
  .areaDetailPurchase .areaContactCardContainer .contact-card .view-contact-details .printShipAddress {
    display: none;
  }
  .areaDetailPurchase .areaContactCardContainer .contact-card .view-contact-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "printCompany"
      "view-name "
      "printAddress "
      "printAddress "
      "view-emailaddr"
      "view-mobilephone"
      "view-otherphone";
  }
  .areaDetailPay .areaContactCardContainer .contact-card .view-contact-details,
  .areaDetailOrder .areaContactCardContainer .contact-card .view-contact-details,
  .areaDetailRepair .areaContactCardContainer .contact-card .view-contact-details,
  .areaDetailQuote .areaContactCardContainer .contact-card .view-contact-details,
  .areaDetailInvoice .areaContactCardContainer .contact-card .view-contact-details {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "printCompany ."
      "view-name printShipAddress"
      "printAddress printShipAddress"
      "printAddress printShipAddress"
      "view-emailaddr ."
      "view-mobilephone ."
      "view-otherphone .";
  }

  .printCompany {
    grid-area: printCompany;
  }
  .printShipAddress {
    grid-area: printShipAddress;
  }
  .view-name {
    grid-area: view-name;
  }
  .printAddress {
    grid-area: printAddress;
  }
  .printAddress {
    grid-area: printAddress;
  }
  .view-emailaddr {
    grid-area: view-emailaddr;
  }
  .view-mobilephone {
    grid-area: view-mobilephone;
  }
  .view-otherphone {
    grid-area: view-otherphone;
  }

  /********** Product Detail *********/

  .productview {
    display: grid;
    grid-template-columns: 80% 20%;
  }
  .product-details {
    display: grid;
    grid-template-columns: 33% 66%;
  }
  .areaProductName {
    margin-top: 1em;
    font-size: var(--fontPrintHeading);
    font-weight: bold;
  }
  .product-suppliers {
    display: grid;
    grid-template-columns: 1fr 15% 15% 15%;
  }
  .areaBaseDetailContainer.areaDetailProduct {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo               areaClientTaxID              areaClientTaxID              areaClientTaxID        ."
      "areaClientLogo               .                            .                            areaIdPanel            areaIdPanel"
      "areaClientLogo               .                            .                            areaProductUPCBarcode  areaProductUPCBarcode"
      "areaClientLogo               .                            .                            .                      ."
      "areaHorizontalRule           areaHorizontalRule           areaHorizontalRule           areaHorizontalRule     areaHorizontalRule"
      "areaClientName               areaClientAddress1           areaClientCityStateZip       areaClientPhone        areaClientWeb"
      "areaProductName              areaProductName              areaProductName              areaProductName        areaProductName"
      "areaProductDescLabel         areaProductDescLabel         areaProductDescLabel         areaProductDescLabel   areaProductDescLabel"
      "areaProductDesc              areaProductDesc              areaProductDesc              areaProductDesc        areaProductDesc"
      "areaProductSellPriceLabel    areaProductInventoryLabel    .                            .                      ."
      "areaProductSellPrice         areaProductInventory         .                            .                      ."
      "areaProductStoreSkuLabel     areaProductUPCLabel          areaProductEANLabel          .                      ."
      "areaProductStoreSku          areaProductUPC               areaProductEAN               .                      ."
      "areaProductFamilyLabel       areaProductMakeLabel         areaProductModelLabel        .                      ."
      "areaProductFamily            areaProductMake              areaProductModel             .                      ."
      "areaProductMaxDiscountLabel areaProductCommissionLabel    .                            .                      ."
      "areaProductMaxDiscount      areaProductCommission         .                            .                      ."
      "areaProductSupplierNameLabel .                            areaProductSupplierCostLabel areaProductSupplierSkuLabel ."
      "areaProductSupplierName      areaProductSupplierName      areaProductSupplierCost      areaProductSupplierSku      .";
  }

  /********** Supplier Detail *********/
  .areaBaseDetailContainer.areaDetailSupplier {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck";
  }

  /********** Return Detail *********/
  .invoiceproductlist {
    margin-top: 2em;
    display: grid;
    grid-template-columns: auto 6% 10% 10% 12.5%;
  }
  .payproductlist {
    margin-top: 2em;
    display: grid;
    grid-template-columns: auto 15% 15% 15%;
    column-gap: 0.75em;
  }
  .returnproductlist {
    display: grid;
    grid-template-columns: 1fr max-content max-content max-content min-content;
  }
  .areaPaymentList .orderpaymentlist {
    grid-template-columns: auto 15%;
  }

  .areaBaseDetailContainer.areaDetailReturn {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  /********** Order Detail *********/
  .orderproductlist {
    display: grid;
    grid-template-columns: auto 6% 10% 10% 10% max-content;
  }
  .areaBaseDetailContainer.areaDetailOrder {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . areaBarCode areaBarCode"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  /********** Repair Detail *********/
  .areaRepairItemInputContainer.statusbadge {
    width: auto;
  }
  .areaRepairItemInputContainer .repairlabel,
  .areaRepairItemInputContainer .statusbadge {
    text-align: left;
  }
  .areaRepairItemInputContainer {
    column-gap: 1em;
    padding: 0;
  }

  .areaRepairItemInputContainer label,
  .areaRepairItemInputContainer span {
    max-width: 100%;
  }
  .areaRepairItemInputContainer.description label {
    margin-right: 1em;
  }

  .areaRepairCardDeck {
    margin: 1em 0;
  }
  .repairproductlist {
    margin-top: 2em;
    display: grid;
    grid-template-columns: auto 6% 10% 10% 12.5% max-content;
  }

  .areaRepairItemCard {
    margin: 0;
    display: grid;
    row-gap: 0;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-template-areas:
      "model"
      "serial"
      "status "
      "technician"
      "description";
  }
  .areaBaseDetailContainer.areaDetailPay,
  .areaBaseDetailContainer.areaDetailInvoice,
  .areaBaseDetailContainer.areaDetailRepair {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . areaBarCode areaBarCode"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaRepairCardDeck areaRepairCardDeck areaRepairCardDeck areaRepairCardDeck areaRepairCardDeck"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      " . . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  /* Report Detail */
  .areaBaseDetailContainer.areaDetailReport {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaReportBody areaReportBody areaReportBody areaReportBody areaReportBody"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer .";
  }

  .reportTable {
    grid-template-columns: 15em 8em;
  }
  .reportTable3 {
    grid-template-columns: 15em 8em 1fr;
  }
  .reportTable6 {
    grid-template-columns: repeat(6, max-content) 1fr;
  }
  .reportTable10 {
    grid-template-columns: repeat(9, max-content) 1fr;
  }
  .reportTable11 {
    grid-template-columns: repeat(10, max-content) 1fr;
  }
  .reportTable12 {
    grid-template-columns: repeat(5, max-content) 3fr repeat(6, max-content) min-content;
  }
  .reportTable12b {
    grid-template-columns: max-content minmax(min-content, 15%) repeat(9, max-content) 1fr;
  }

  .reportSettings {
    display: grid;
    grid-template-columns: max-content 1fr;
  }

  /********** Quote Detail *********/
  .quoteproductlist {
    margin-top: 2em;
    display: grid;
    grid-template-columns: 1fr max-content 15% max-content 15%;
  }
  .areaBaseDetailContainer.areaDetailQuote {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . areaBarCode areaBarCode"
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  /********** Purchase Detail *********/
  .purchaseproductlist {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content max-content max-content;
  }
  .areaBaseDetailContainer.areaDetailPurchase {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo areaIdPanel areaIdPanel areaIdPanel areaIdPanel"
      "areaClientLogo . . areaBarCode areaBarCode"
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck areaContactCardDeck"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  /********** Billing Subscription Detail *********/
  .areaRecurringSubscriptionsNarrow,
  .areaSubscriptionStatus,
  .areaSubscriptionsNarrow {
    display: none;
  }
  .statusbadge {
    text-align: center;
  }
  .customFieldsContainerPrint {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    margin-top: 0 !important;
  }

  .areaRecurringSubscriptions {
    display: grid;
    grid-template-columns: 1fr repeat(min-content, 9);
  }

  .areaSubscriptions {
    display: grid;
    grid-template-columns: 1fr repeat(min-content, 9);
  }

  .areaBaseDetailContainer.areaDetailRecurring.tabnone,
  .areaBaseDetailContainer.areaDetailRecurring.tabowing,
  .areaBaseDetailContainer.areaDetailRecurring.tabinactive,
  .areaBaseDetailContainer.areaDetailRecurring.tabactive {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck . areaStoredPaymentsHeader areaStoredPaymentsHeader"
      "areaContactCardDeck areaContactCardDeck . areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck . . ."
      "areaContactCardDeck areaContactCardDeck . areaOverdueTotal areaOverdueTotal"
      "areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }

  .areaBaseDetailContainer.areaDetailBilling.tabnone,
  .areaBaseDetailContainer.areaDetailBilling.tabowing,
  .areaBaseDetailContainer.areaDetailBilling.tabinactive,
  .areaBaseDetailContainer.areaDetailBilling.tabactive {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-areas:
      "areaClientLogo areaClientTaxID areaClientTaxID areaClientTaxID ."
      "areaClientLogo . . . ."
      "areaClientLogo . . . ."
      "areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule areaHorizontalRule"
      "areaClientName areaClientAddress1 areaClientCityStateZip areaClientPhone areaClientWeb"
      "areaHeader areaHeader areaHeader areaHeader areaHeader"
      "areaContactCardDeck areaContactCardDeck . areaStoredPaymentsHeader areaStoredPaymentsHeader"
      "areaContactCardDeck areaContactCardDeck . areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck . . ."
      "areaContactCardDeck areaContactCardDeck . areaOverdueTotal areaOverdueTotal"
      "areaSubscriptions areaSubscriptions areaSubscriptions areaSubscriptions areaSubscriptions"
      "areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes areaPrintedNotes";
  }
  .areaStoredPayments {
    grid-template-columns: 1fr 1fr max-content;
  }
  /********** Billing Plan Detail *********/

  .areaDetailRecurring.tabPlans,
  .areaDetailBilling.tabplans {
    grid-template-areas:
      "areaClientLogo"
      "areaClientLogo"
      "areaClientLogo"
      "areaHorizontalRule "
      "areaClientName"
      "areaHader"
      "areaHeader"
      "areaPlanSettings";
  }
}
