/******* Hide print-only content when screen **************************/
@media screen {
  .areaBarCode,
  .areaBarCodeLabel,
  .areaBarCodeNarrow,
  .areaClient,
  .areaClientAddress1,
  .areaClientCityStateZip,
  .areaClientEmail,
  .areaClientLogo,
  .areaClientName,
  .areaClientPhone,
  .areaClientTaxID,
  .areaClientWeb,
  .areaHorizontalRule,
  .areaPageTitle,
  .areaPONumber,
  .areaPONumberLabel,
  .areaPrintedNotes,
  .areaTrackingNumber,
  .areaTrackingNumberLabel,
  .headerRule4,
  .headerRule5,
  .headerRule6,
  .headerRule7,
  .headerRule8,
  .headerRule9,
  .campaignSwitchesHr,
  .inputAsSpan,
  .printDivider,
  .printOnly,
  .printOnlyInline,
  .receiptOnly,
  .productLabel,
  .productElement,
  .printCompany,
  .printAddress,
  .printShipAddress,
  .printPurchaseLabel,
  .printRepairLabel,
  .taxTotalLabel,
  .taxTotalValue {
    display: none;
  }
}

/******* Prevent unrelated content from being displayed ******************************/
/******* For example, don't show the product card on the Customer screen *************/
.areaBarCodeNarrow,
.areaProductListNarrow,
.areaSubscribeContainer,
.areaFoldersContainer,
.areaSwitchesProduct,
.areaSwitchesSupplier,
.areaSwitchesCustomer,
.areaCreationDate,
.areaDueDate,
.areaSalesperson,
.areaPONumber,
.areaPONumberLabel,
.areaTrackingNumber,
.areaTrackingNumberLabel,
.areaOrderNumber,
.areaBaseDetailContainer.areaDetailPurchase .areaPaymentList,
.areaBaseDetailContainer.areaDetailCustomer .areaAddProductContainer,
.areaBaseDetailContainer.areaDetailPay .areaRepairCardDeck,
.areaBaseDetailContainer.areaDetailCustomer .areaProductCard {
  display: none;
}

/********** Manage the mobile vs desktop toggling *****************************/
.desktop,
.desktop-inline,
.desktop-grid {
  display: none;
}
.hide {
  display: none !important;
}
.mobile-grid {
  display: grid;
}

@media screen and (min-width: 1024px) {
  .mobile,
  .mobile-grid,
  .nav-logo,
  .mobile-header {
    display: none;
  }
  .desktop {
    display: block;
  }
  .desktop-inline {
    display: inline-block;
  }
  .desktop-grid {
    display: grid;
  }
}
