.areaSubscribeContainer {
  grid-area: areaSubscribeContainer;
}

.areaAddProductContainer {
  grid-area: areaAddProductContainer;
}

.areaBarCodeNarrow,
.areaBarCode {
  grid-area: areaBarCode;
}

.areaProductUPC {
  grid-area: areaProductUPC;
}

.areaProductUPCLabel {
  grid-area: areaProductUPCLabel;
}

.areaProductEANLabel {
  grid-area: areaProductEANLabel;
}
.areaProductEAN {
  grid-area: areaProductEAN;
}

.areaProductUPCBarcode {
  grid-area: areaProductUPCBarcode;
}

.areaButtonControlsContainer {
  grid-area: areaButtonControlsContainer;
}

.areaClient {
  grid-area: areaClient;
}

.areaClientName {
  grid-area: areaClientName;
}

.areaClientAddress1 {
  grid-area: areaClientAddress1;
}

.areaClientCityStateZip {
  grid-area: areaClientCityStateZip;
}

.areaClientEmail {
  grid-area: areaClientEmail;
}

.areaClientLogo {
  grid-area: areaClientLogo;
}

.areaClientPhone {
  grid-area: areaClientPhone;
}

.areaClientWeb {
  grid-area: areaClientWeb;
}

.areaClientTaxID {
  grid-area: areaClientTaxID;
}

.areaCloseButton {
  grid-area: areaCloseButton;
}

/* areaContactCardContainer and areaCustomerSearchContainer are mutually exclusive and renders based on object */
.areaContactCardContainer,
.areaCustomerSearchContainer {
  grid-area: areaContactCardDeck;
}
/* areaHeader components are mutually exclusive and renders based on object. 
Each header points to areaHeader to remain generic 
Note: customer and supplier both use contact */
.areaCampaignHeader,
.areaBillingHeader,
.areaReportHeader,
.areaContactHeader,
.areaProductHeader {
  grid-area: areaHeader;
}

.areaIdPanel {
  grid-area: areaIdPanel;
}

.areaCreationDate {
  grid-area: areaCreationDate;
}

.areaDueDate {
  grid-area: areaDueDate;
}

.areaFoldersContainer {
  grid-area: areaFoldersContainer;
}

.areaPrintedNotes {
  grid-area: areaPrintedNotes;
}

.areaHorizontalRule {
  grid-area: areaHorizontalRule;
}

.areaReportBody {
  grid-area: areaReportBody;
}

.areaOrderNumber {
  grid-area: areaOrderNumber;
}

.areaOrderStatus {
  grid-area: areaOrderStatus;
}

.areaProspectStatus {
  grid-area: areaProspectStatus;
}

.areaOrderTotalContainer {
  grid-area: areaOrderTotalContainer;
}

.areaPageTitle {
  grid-area: areaPageTitle;
}

.areaPaymentList {
  grid-area: areaPaymentList;
}

.areaPaymentMethods {
  grid-area: areaPaymentMethods;
}

.areaPONumber {
  grid-area: areaPONumber;
}
.areaPONumberLabel {
  grid-area: areaPONumberLabel;
}

.areaTrackingNumber {
  grid-area: areaTrackingNumber;
}
.areaTrackingNumberLabel {
  grid-area: areaTrackingNumberLabel;
}

.areaProductCard {
  grid-area: areaProductCard;
}

.areaProductDesc {
  grid-area: areaProductDesc;
}

.areaProductDescLabel {
  grid-area: areaProductDescLabel;
}

.areaProductInventory {
  grid-area: areaProductInventory;
}

.areaProductInventoryLabel {
  grid-area: areaProductInventoryLabel;
}

.areaProductListNarrow,
.areaProductList {
  grid-area: areaProductList;
}

.areaProductName {
  grid-area: areaProductName;
}

.areaProductSearchContainer {
  grid-area: areaProductSearchContainer;
}

.areaProductSellPrice {
  grid-area: areaProductSellPrice;
}

.areaProductSellPriceLabel {
  grid-area: areaProductSellPriceLabel;
}

.areaProductStoreSku {
  grid-area: areaProductStoreSku;
}

.areaProductStoreSkuLabel {
  grid-area: areaProductStoreSkuLabel;
}

.areaProductSupplierCost {
  grid-area: areaProductSupplierCost;
}

.areaProductSupplierCostLabel {
  grid-area: areaProductSupplierCostLabel;
}

.areaProductSupplierName {
  grid-area: areaProductSupplierName;
}

.areaProductSupplierNameLabel {
  grid-area: areaProductSupplierNameLabel;
}

.areaProductSupplierSku {
  grid-area: areaProductSupplierSku;
}

.areaProductSupplierSkuLabel {
  grid-area: areaProductSupplierSkuLabel;
}

.areaProductMaxDiscount {
  grid-area: areaProductMaxDiscount;
}

.areaProductMaxDiscountLabel {
  grid-area: areaProductMaxDiscountLabel;
}

.areaProductCommission {
  grid-area: areaProductCommission;
}

.areaProductCommissionLabel {
  grid-area: areaProductCommissionLabel;
}

.areaProductFamily {
  grid-area: areaProductFamily;
}

.areaProductFamilyLabel {
  grid-area: areaProductFamilyLabel;
}

.areaProductMake {
  grid-area: areaProductMake;
}

.areaProductMakeLabel {
  grid-area: areaProductMakeLabel;
}

.areaProductModel {
  grid-area: areaProductModel;
}

.areaProductModelLabel {
  grid-area: areaProductModelLabel;
}

.areaSalesperson {
  grid-area: areaSalesperson;
}

.areaStoreCredit {
  grid-area: areaStoreCredit;
}
.areaSwitchesCampaign,
.areaSwitchesCustomer,
.areaSwitchesSupplier,
.areaSwitchesProduct {
  grid-area: areaSwitches;
}

.areaRepairCardDeck {
  grid-area: areaRepairCardDeck;
}

.areaProspectAdditionalData {
  grid-area: areaProspectAdditionalData;
}

.areaProspectAgreement {
  grid-area: areaProspectAgreement;
}

.areaProductManagerFields {
  grid-area: areaProductManagerFields;
}

.description {
  grid-area: description;
}

.family {
  grid-area: family;
}

.model {
  grid-area: model;
}

.repairadd {
  grid-area: repairadd;
}

.repairdelete {
  grid-area: repairdelete;
}

.serial {
  grid-area: serial;
}

.status {
  grid-area: status;
}

.technician {
  grid-area: technician;
}
.areaSearch {
  grid-area: areaSearch;
}
.areaCategories {
  grid-area: areaCategories;
}

.areaDetailBilling {
  grid-area: areaDetailBilling;
}
.areaTemplateSettings,
.areaPlanSettings {
  grid-area: areaPlanSettings;
}
.areaSubscriptionStatus {
  grid-area: areaSubscriptionStatus;
}
.areaStoredPayments {
  grid-area: areaStoredPayments;
}
.areaStoredPaymentsHeader {
  grid-area: areaStoredPaymentsHeader;
}
.areaSubscriptionsNarrow,
.areaSubscriptions {
  grid-area: areaSubscriptions;
}

.areaRecurringSubscriptionsNarrow,
.areaRecurringSubscriptions {
  grid-area: areaRecurringSubscriptions;
}

.areaBillingPlanSearchContainer {
  grid-area: areaBillingPlanSearchContainer;
}
.areaOverdueTotal {
  grid-area: areaOverdueTotal;
}
.areaCampaignLink {
  grid-area: areaCampaignLink;
}

.areaCampaignDetails {
  grid-area: areaCampaignDetails;
}
