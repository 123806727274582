.search-wrapper {
  display: flex;
  margin: auto 0 auto auto;
}
#list-search-input-container {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  background-color: var(--search-background);
  color: var(--text);
  border-radius: var(--border-radius-extralarge);
  border: 1px solid var(--defaultBodyBorderColor);
  outline-width: 0;
  outline: none;
  padding: 0.4em;
  gap: 0.25em;
  margin: auto 0;
}
#list-search-input-container select,
#list-search-input-container svg,
#list-search-input-container input,
#list-search-input-container span {
  background-color: var(--search-background);
  border: none;
  color: var(--text);
  margin: auto;
}
#list-search-input-container input {
  width: 10em;
  border: none;
  outline-width: 0;
  outline: none;
}
.header-container #list-search-input-container input {
  width: 12em;
} /* Search Everything */
.header-container .search-wrapper {
  font-size: var(--font-size-desktop-default);
} /* Search Everything */
/*Media Query for desktops*/
@media screen and (min-width: 1024px) {
  #list-search-input-container {
    margin: auto;
  }
  #list-search-input-container input {
    width: 17em;
  }
}
