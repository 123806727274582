#wheel-clipper {
  position: absolute;
  height: 50vw;
  width: 50vw;
  top: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
}
#wheel-wrapper {
  position: absolute;
  height: 100vw;
  width: 100vw;
  top: -50vw;
  right: -50vw;
  transform: scale(1);
  transition: transform 0.5s;
  /* Prevent "click" events from firing. Only uses "touch" events */
  pointer-events: none;
}

/*For design purposes only*/
#wheel-clipper.center {
  position: relative;
  top: 50vw;
  left: 0;
  overflow: visible;
}

/* Area of the control the user grips to spin the wheel */
#wheel-spinner {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--wheel);
  border-radius: var(--border-radius-round);
  pointer-events: auto;
}

/* Area of the control that displays the menu icons */
#wheel-menu {
  background-color: var(--search-background);
  height: 70%;
  width: 70%;
  top: 15%;
  left: 15%;
  position: relative;
  border-radius: var(--border-radius-round);
  pointer-events: auto;
}

#menu-gear {
  position: absolute;
  top: 52%;
  left: 38%;
  z-index: 11;
}

#menu-gear svg {
  height: 15%;
  width: 15%;
}
#wheel-menu {
  font-size: var(--font-size-mobile-default);
}

#wheel-menu .m11 {
  position: absolute;
  top: 18%;
  left: 17%;
  transition: all 0.15s ease;
}

#wheel-menu .m10 {
  position: absolute;
  top: 35%;
  left: 7%;
  transition: all 0.15s ease;
}

#wheel-menu .m9 {
  position: absolute;
  top: 52%;
  left: 7%;
  transition: all 0.15s ease;
}

#wheel-menu .m8 {
  position: absolute;
  top: 70%;
  left: 17%;
  transition: all 0.15s ease;
}

#wheel-menu .m7 {
  position: absolute;
  top: 81%;
  left: 34%;
  transition: all 0.15s ease;
}

#wheel-menu .m6 {
  position: absolute;
  top: 81%;
  left: 53%;
  transition: all 0.15s ease;
}

#wheel-menu .m5 {
  position: absolute;
  top: 70%;
  left: 71%;
  transition: all 0.15s ease;
}

#wheel-menu .m4 {
  position: absolute;
  top: 52%;
  left: 82%;
  transition: all 0.15s ease;
}

#wheel-menu .m3 {
  position: absolute;
  top: 35%;
  left: 82%;
  transition: all 0.15s ease;
}

#wheel-menu .m2 {
  position: absolute;
  top: 18%;
  left: 71%;
  transition: all 0.15s ease;
}

#wheel-menu .m1 {
  position: absolute;
  top: 8%;
  left: 54%;
  transition: all 0.15s ease;
}

#wheel-menu .m0 {
  position: absolute;
  top: 8%;
  left: 34%;
  transition: all 0.15s ease;
}

#wheel-menu svg {
  font-size: var(--font-size-wheel-icon);
  height: 20em;
}

#wheel-wrapper svg {
  fill: var(--wheelIcon);
  color: var(--wheelIcon);
}

.selected-menu svg {
  fill: var(--wheel) !important;
  color: var(--wheel) !important;
}

/*Media Query for tablets*/
@media screen and (min-width: 450px) and (max-width: 1023px) {
  #menu-wheel-gear {
    font-size: var(--font-size-tablet-icon);
  }
}

/*Media Query for large desktops*/
@media screen and (min-width: 1024px) {
  #wheel-wrapper {
    display: none;
  }
}
