@media print {
  .inputAsSpan,
  .desktop-inline {
    display: inline-block;
  }
  .desktop {
    display: block;
  }

  .areaBaseDetailContainer.areaDetailPay .areaRepairCardDeck,
  .printDivider,
  .printOnly,
  .areaIdPanel .areaCreationDate,
  .areaIdPanel .areaAgreementDate,
  .areaIdPanel .areaDueDate,
  .areaIdPanel .areaSalesperson,
  .areaIdPanel .areaStoreCredit,
  .areaIdPanel .areaPONumberLabel,
  .areaIdPanel .areaTrackingNumberLabel,
  .areaIdPanel .areaOrderNumber {
    display: block;
  }
  .printOnlyInline {
    display: inline;
  }

  body,
  input,
  textarea,
  .list-grid,
  .app-body,
  .areaBaseDetailContainer,
  .areaInputItem input,
  .areaInputItem textarea,
  .areaRepairItemInputContainer .popup,
  .areaRepairItemInputContainer input,
  .areaRepairItemInputContainer textarea,
  .areaRepairItemInputContainer,
  .areaSwitchesCampaign,
  .detail-container,
  .dialer,
  .header-container,
  .list-item,
  .model,
  .product-suppliers,
  .productElement,
  .repairlabel,
  .reporttabletotal,
  .statusbadge,
  .subscriptionCard,
  .recurringSubscriptionCard,
  .areaPlanSettings,
  .areaTemplateSettings,
  .supplierwidget-item input,
  .total .subTotalLabel,
  .total .subTotalValue,
  .total .taxTotalLabel,
  .total .taxTotalValue,
  .total .totalLabel,
  .total .totalPaymentsLabel,
  .total .totalPaymentsValue,
  .total .totalValue {
    background-color: white !important;
    color: black !important;
  }

  label,
  .metricReportHeaders,
  .areaRepairItemInputContainer label,
  .areaStoreCredit,
  .component-title,
  .header,
  .templateInputItem select,
  .planInputItem select,
  .planDescription,
  .product-header,
  .productLabel,
  .subject,
  .dashboardWidget,
  .areaCampaignDetails.areaInputItem label,
  .supplierwidget-header {
    background-color: transparent;
    color: black;
    font-weight: bold;
  }

  /* Do not display on Print media */
  input[type="datetime-local"]::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-calendar-picker-indicator,
  .timesheetNote,
  .action-button,
  .action-icon-container,
  .action-tab,
  .applicationHeader,
  .areaSubscribeContainer,
  .areaButtonControlsContainer,
  .areaCloseButton,
  .areaContactHeader,
  .areaProductCard,
  .areaProductHeader,
  .areaProductSearchContainer,
  .areaProductManagerFields,
  .circle-button,
  .circlebuttoncontainer,
  .contact-toggles,
  .contact-view-top-row,
  .control-buttons,
  .display-order-num,
  .saveProducts,
  .downloadProducts,
  .eyeball,
  .family,
  .reportFilterType,
  .filter-by,
  .folders,
  .headerlast,
  .listControlsContainer,
  .mobile-header,
  .mobile-screen-title,
  .mobile,
  .mobileviewphone,
  .nav-button-container,
  .new-po,
  .noPrint,
  .paginationContainer,
  .partial-toggle,
  .pay-toggles,
  .payment-action-buttons,
  .payment-icon-container,
  .popuplist,
  .product-toggles,
  .recordnumber,
  .removeCompanyContainer,
  .search-wrapper,
  .sorticon,
  .supplierwidget-item.action-icon,
  #list-container .expander,
  .taxSummaryLabel,
  .taxSummaryValue,
  .purchaseCustomer,
  .purchaseStatus,
  .areaOrderStatus,
  .purchaseReceived,
  .editButton,
  .paymentMethodDelete,
  .checkboxContainer,
  .planFrequency,
  .areaBillingPlanSearchContainer,
  .areaSubscriptionStatus,
  .testBadge,
  .planInputItem input,
  .templateInputItem input,
  .templateInputItem select,
  .displayEmailIcon,
  .messagelist .list-grid .panel_left,
  .dbwExpander,
  .addWidget,
  .sourceorder,
  .dbwTitle svg,
  .superScript svg,
  .editPencil,
  .top-row,
  .copyToClipboard,
  .creationDetail.areaDueDate.empty {
    display: none;
  }

  .areaCampaignDetails.areaInputItem textarea {
    overflow: hidden;
  }
  .areaCampaignDetails.areaInputItem label {
    display: block;
    padding-top: 1em;
  }
  .areaCampaignLink {
    margin: 2em 0;
  }

  .newTooltip {
    display: none !important;
  }
  .dashboardWidget,
  .recurringSubscriptionCard,
  .subscriptionCard {
    border: none;
  }
  .orderTagGrid {
    grid-template-columns: 1fr 17% 17%;
  }

  .overdueTotalAmount {
    color: black;
    border: solid 1px #000;
    padding: 0.25em;
    font-size: var(--fontPrint);
  }
  .email_card,
  .sms_card {
    background-color: transparent;
  }

  .messageDateTime {
    font-size: 100%;
    opacity: revert;
  }

  .subTotalLabel,
  .subTotalValue,
  .taxTotalLabel,
  .taxTotalValue,
  .totalLabel,
  .totalPaymentsLabel,
  .totalPaymentsValue,
  .totalValue {
    margin-left: 0;
  }

  .totalPaymentsValue,
  .subTotalValue,
  .taxTotalValue,
  .totalValue {
    text-align: right;
  }

  .contact-item,
  .contact-card {
    background-color: transparent;
    color: black;
    padding-left: 0;
    max-width: 100%;
  }

  .areaReportHeader {
    margin-top: 1em;
  }
  .printMessageCompany {
    background-color: transparent;
    color: black;
    font-weight: bold;
    font-size: larger;
    padding-left: 0;
  }
  .printCompany {
    background-color: transparent;
    color: black;
    font-weight: bold;
    font-size: larger;
    padding-left: 0;
    padding-bottom: 0.25em;
  }
  .contact-card {
    margin: 1em 0;
  }

  input {
    padding: 0;
  }
  .total .totalPaymentsValue,
  .total .subTotalValue,
  .total .taxTotalValue,
  .total .totalValue {
    padding-right: 0;
  }

  .ponumbercontainer {
    margin: 0.5em 0 0 0;
  }
  #quantity {
    text-align: center;
  }
  .repairlabel {
    text-align: left;
  }
  .ponumber {
    text-align: right;
  }

  .areaPrintedNotes {
    margin-top: 1em;
  }
  .noteLabel {
    font-weight: bold;
  }
  .productLabel {
    font-weight: bold;
    margin-top: 1em;
  }
  .areaStoredPayments {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }

  .recurringSubscriptionCard .statusbadge {
    text-align: left;
    padding: 0;
  }

  .templateInputItem .inputLabel {
    width: 25%;
    margin-bottom: 1em;
  }
}
