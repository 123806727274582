@media print and (max-width: 110mm) {
  body {
    font-size: var(--fontPrint);
    font-family: "Arial Narrow", Arial, sans-serif;
  }

  .noReceipt,
  .areaIdPanel,
  .printAddress,
  .areaProductManagerFields,
  .printShipAddress {
    display: none;
  }

  .areaCreationDate,
  .areaDueDate,
  .areaSalesperson,
  .areaPONumberLabel,
  .areaTrackingNumberLabel,
  .areaOrderNumber,
  .areaContactCardContainer .contact-card .view-contact-details {
    display: block;
  }

  /********** List Screens *********/
  #list-container .desktop {
    display: none;
  }
  #list-container .mobile {
    display: block;
  }
  #list-container .list-grid {
    display: grid;
    column-gap: 0.75em;
    overflow-wrap: break-word;
    padding: 0;
  }
  #list-container .list-item,
  #list-container .header {
    padding-left: 0;
    padding-right: 0;
  }

  .invoiceListTotalsGrid {
    font-size: var(--font-size-medium);
    margin: 1em 0;
    padding: 1em 0;
  }

  /********** Customer List *********/
  .customerlist .list-grid {
    grid-template-columns: auto auto;
  }
  .customerlist .list-header-caret {
    grid-column: 1 / span 1;
  }

  .customerlist.prospectlist .list-grid {
    grid-template-columns: repeat(3, auto);
  }

  /********** Product List *********/
  .productlist .list-grid {
    grid-template-columns: auto auto;
  }
  .productlist .list-header1 {
    grid-column: 1 / span 1;
  }

  /********** Supplier List *********/
  .supplierlist .list-grid {
    grid-template-columns: auto auto;
  }
  .supplierlist .list-header1 {
    grid-column: 1 / span 1;
  }

  /********** Detail Screens *********/
  .areaBaseDetailContainer {
    margin: 0 0.75em 0 0;
    padding: 0;
  }
  .productListGrid .header,
  .productListGrid .list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .productListGrid {
    margin-top: 1em;
    overflow-wrap: break-word;
    display: grid;
    grid-template-columns: auto max-content max-content max-content;
    column-gap: 1em;
  }
  .areaPaymentList .orderpaymentlist {
    grid-template-columns: auto max-content;
  }
  .totalcontainer {
    grid-template-columns: auto auto max-content;
  }

  .areaProspectAdditionalData {
    margin: auto;
    padding: 2em 0;
  }
  .additionalDataHeader,
  .creationDetail,
  .clientDetail {
    width: 100%;
    text-align: center;
  }
  .areaClientLogo img {
    height: 160px;
    width: auto;
  }
  .areaSalesperson,
  .areaOrderNumber {
    margin: 0.25em 0;
  }
  .headerRule3 {
    display: block;
    width: 100%;
    grid-column: 1 / span 3;
  }
  .reportTable {
    display: grid;
    grid-template-columns: 1fr 8em;
    column-gap: 1em;
  }
  .areaReportHeader {
    text-align: center;
  }
  .reportEndDate,
  .reportStartDate,
  .timesheetEndDate,
  .timesheetStartDate {
    grid-template-columns: 50% 50%;
  }

  .areaBarCode,
  .areaClientTaxID,
  .areaHorizontalRule,
  .areaProductList,
  .headerRule4,
  .headerRule5,
  .headerRule6,
  .headerRule7,
  .headerRule8 {
    display: none;
  }
  .areaBarCodeLabel,
  .areaBarCodeNarrow,
  .areaProductListNarrow {
    display: block;
  }
  .areaProductList,
  .areaPONumberLabel,
  .areaTrackingNumberLabel,
  .areaContactHeader {
    margin-top: 1em;
  }
  .contact-item,
  .contact-card {
    padding: 0;
    margin-bottom: 0;
  }

  /********** Customer Detail Screens *********/
  .areaContactCardContainer {
    text-align: center;
  }
  .areaBaseDetailContainer.areaDetailCustomer {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaStoreCredit"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail";
  }

  .areaBaseDetailContainer.areaDetailCustomer.tabprospects {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaProspectAdditionalData"
      "areaStoreCredit"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaProspectAgreement";
  }

  .areaDetailCampaign {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaSwitches"
      "areaCampaignDetails"
      "areaCampaignLink"
      "areaStoreCredit"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail";
  }

  /********** Return Detail Screens *********/

  .areaBaseDetailContainer.areaDetailReturn {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPONumberLabel"
      "areaProductList"
      "areaPaymentList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }
  /********** Order Detail Screens *********/
  .areaBaseDetailContainer.areaDetailOrder {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPONumberLabel"
      "areaProductList"
      "areaPaymentList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }

  /********** Invoice/Repair Detail Screens *********/
  .areaRepairItemCard .statusbadge {
    text-align: left;
  }
  .areaRepairItemInputContainer {
    padding: 0;
  }
  .areaRepairItemCard {
    margin: 0;
    display: grid;
    grid-template-columns: auto;
    gap: 0;
    grid-template-areas:
      "model"
      "serial"
      "status"
      "technician"
      "description";
  }

  .areaBaseDetailContainer.areaDetailPay,
  .areaBaseDetailContainer.areaDetailInvoice {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPONumberLabel"
      "areaRepairCardDeck"
      "areaProductList"
      "areaPaymentList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }

  .areaBaseDetailContainer.areaDetailRepair {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaDueDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPONumberLabel"
      "areaRepairCardDeck"
      "areaProductList"
      "areaPaymentList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }

  /********** Report Detail Screens *********/
  .areaDetailReport {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaReportBody"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail";
  }

  /********** Quote Detail Screens *********/
  .areaBaseDetailContainer.areaDetailQuote {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPONumberLabel"
      "areaProductList"
      "areaPaymentList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }
  /********** Supplier Detail Screens *********/
  .areaBaseDetailContainer.areaDetailSupplier {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail";
  }
  /********** Purchase Detail Screens *********/
  .areaBaseDetailContainer.areaDetailPurchase {
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaTrackingNumberLabel"
      "areaProductList"
      "areaOrderTotalContainer"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }
  /********** Subscription Detail Screens *********/

  .areaStoredPayments {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .areaStoredPayments .header {
    padding-left: 0;
  }

  .recurringSubscriptionCard,
  .subscriptionCard {
    padding: 0;
  }

  .recurringSubscriptionCard label,
  .subscriptionCard label {
    margin-bottom: 0.25em;
  }

  .areaBaseDetailContainer.areaDetailBilling.tabnone .view-contact-details::before,
  .areaBaseDetailContainer.areaDetailBilling.tabowing .view-contact-details::before,
  .areaBaseDetailContainer.areaDetailBilling.tabinactive .view-contact-details::before,
  .areaBaseDetailContainer.areaDetailBilling.tabactive .view-contact-details::before {
    content: "Subscription Account ";
    font-size: 110%;
    font-weight: bold;
    text-align: center;
  }

  .areaSubscriptionsNarrow::before {
    content: "Subscriptions: ";
    font-size: 110%;
    font-weight: bold;
    border-bottom: 1px solid #000;
  }

  .overdueTotalAmount {
    margin-bottom: 1em;
  }

  .areaBaseDetailContainer.areaDetailRecurring.tabnone,
  .areaBaseDetailContainer.areaDetailRecurring.tabowing,
  .areaBaseDetailContainer.areaDetailRecurring.tabinactive,
  .areaBaseDetailContainer.areaDetailRecurring.tabactive {
    column-gap: 0;
    margin: 0.5em;
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaStoredPaymentsHeader"
      "areaStoredPayments"
      "areaOverdueTotal"
      "areaSubscriptions"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }

  .areaBaseDetailContainer.areaDetailBilling.tabnone,
  .areaBaseDetailContainer.areaDetailBilling.tabowing,
  .areaBaseDetailContainer.areaDetailBilling.tabinactive,
  .areaBaseDetailContainer.areaDetailBilling.tabactive {
    column-gap: 0;
    margin: 0.5em;
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaStoredPaymentsHeader"
      "areaStoredPayments"
      "areaOverdueTotal"
      "areaSubscriptions"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }

  /********** Plan Detail Screens *********/
  .areaBaseDetailContainer.areaDetailBilling.tabplans {
    column-gap: 0;
    grid-template-areas:
      "areaClientLogo"
      "areaClientName"
      "areaClientAddress1"
      "areaClientCityStateZip"
      "areaOrderNumber"
      "areaCreationDate"
      "areaHeader"
      "areaContactCardDeck"
      "areaPlanSettings"
      "areaSalesperson"
      "areaClientPhone"
      "areaClientWeb"
      "areaClientEmail"
      "areaBarCode"
      "areaBarCodeLabel"
      "areaPrintedNotes";
  }
  /********** Message List Screens *********/
  .messagelist .list-grid {
    grid-template-columns: 100%;
  }
}
@media print and (max-width: 110mm) {
  /********** Product Detail Screens for Label *********/

  /* Product Elements that do not display on label/reciept */
  .areaDetailProduct .areaSalesperson,
  .areaDetailProduct .areaClientPhone,
  .areaDetailProduct .areaClientWeb,
  .areaDetailProduct .areaClientEmail,
  .areaDetailProduct .areaClientName,
  .areaDetailProduct .areaClientAddress1,
  .areaDetailProduct .areaClientCityStateZip,
  .areaDetailProduct .areaCreationDate,
  .areaDetailProduct .areaHeader,
  .areaDetailProduct .areaProductInventoryLabel,
  .areaDetailProduct .areaProductInventory,
  .areaDetailProduct .areaProductUPCLabel,
  .areaDetailProduct .areaProductUPC,
  .areaDetailProduct .areaProductSupplierCostLabel,
  .areaDetailProduct .areaProductSupplierCost,
  .areaDetailProduct .areaProductStoreSkuLabel,
  .areaDetailProduct .areaProductStoreSku,
  .areaDetailProduct .areaProductSupplierNameLabel,
  .areaDetailProduct .areaProductSupplierName,
  .areaDetailProduct .areaProductSupplierSkuLabel,
  .areaDetailProduct .areaProductSupplierSku,
  .areaDetailProduct .areaProductDescLabel,
  .areaDetailProduct .areaProductDesc,
  .areaDetailProduct .areaClientLogo,
  .areaDetailProduct .areaProductEANLabel,
  .areaDetailProduct .areaProductEAN,
  .areaDetailProduct .areaProductMaxDiscountLabel,
  .areaDetailProduct .areaProductMaxDiscount,
  .areaDetailProduct .areaProductFamilyLabel,
  .areaDetailProduct .areaProductFamily,
  .areaDetailProduct .areaProductCommissionLabel,
  .areaDetailProduct .areaProductCommission,
  .areaDetailProduct .areaProductMakeLabel,
  .areaDetailProduct .areaProductMake,
  .areaDetailProduct .areaProductModelLabel,
  .areaDetailProduct .areaProductModel,
  .areaDetailProduct .areaProductSellPriceLabel {
    display: none;
  }

  .areaProductName {
    font-size: var(--fontPrint);
    text-align: center;
    margin-top: 0.2em;
    overflow-wrap: break-word;
  }

  .areaProductSellPrice {
    font-size: var(--fontUPC);
    margin: auto;
    padding-top: 0.1em;
  }
  .areaProductUPCBarcode svg {
    transform: scaleX(1.4) !important;
    width: 100% !important;
  }

  .areaDetailProduct {
    margin-top: 2em;
    font-size: var(--fontPrintSmall);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      " areaProductName"
      " areaProductSellPrice"
      " areaProductUPCBarcode";
  }
}

@media print and (max-width: 60mm) {
  /*barcode will not scan at this size so it is hidden */

  .areaProductUPCBarcode {
    display: none;
  }
}
