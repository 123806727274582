.metrics {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
}

.monitoringHeader {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  column-gap: 1em;
}

.calendarWidget {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
}
.calendarWidget div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.centerContents,
.actionButtonStockOrder {
  display: grid;
  grid-template-columns: auto;
}
.actionButtonWalkIn {
  display: grid;
  grid-template-columns: auto;
}

.action-tab-balance {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.amtchargegrid {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

.textPanel {
  display: flex;
  flex-flow: column nowrap;
}

.faqLogo {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1em;
  text-align: center;
  row-gap: 0.5em;
}

.faqContent {
  display: grid;
  grid-template-columns: 100%;
}
.areaAddProductContainer {
  display: grid;
  grid-template-columns: 1fr 23%;
  column-gap: 1em;
}
.areaBaseDetailContainer {
  display: grid;
  grid-template-columns: 100%;
}

.areaBaseDetailContainer.areaDetailSetting {
  display: block;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
}
.campaignGrid {
  display: grid;
  grid-template-columns: auto;
  column-gap: 1em;
}
.areaSwitchesCampaign {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1em;
}
.threeColumnGrid {
  display: grid;
  grid-template-columns: 15% max-content 1fr;
  column-gap: 1em;
}
.orderTagGrid {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 1em;
}
.tagGrid {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 0.5em;
}

.settingsListGrid {
  display: block;
  margin-bottom: 2em;
}

.globalSettings {
  display: grid;
  grid-template-columns: max-content max-content max-content 1fr;
  column-gap: 2em;
  row-gap: 0.5em;
}
.recurringplanssearchresults,
.billingplanssearchresults {
  display: grid;
  column-gap: 0.5em;
  grid-template-columns: 27vw 27vw 15vw;
}
.areaContactSearchResults {
  display: grid;
  grid-template-columns: 36vw 36vw;
}
.dateFilter {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 2em;
}
.orderproductsearchresults {
  display: grid;
  grid-template-columns: 52vw 5vw 15vw;
}
.areaProspectAdditionalData {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.5em;
  row-gap: 0.25em;
  align-self: start;
}

.list-grid {
  display: grid;
}
.customerlist .list-grid {
  grid-template-columns: min-content min-content auto auto;
}
.customerlist.prospectlist .list-grid {
  grid-template-columns: min-content min-content 1fr;
}
.invoicelist .list-grid {
  grid-template-columns: min-content auto min-content;
}
.orderlist .list-grid {
  grid-template-columns: min-content 1fr max-content;
}
.productlist .list-grid {
  grid-template-columns: min-content auto auto;
}
.productlist.inventory .list-grid {
  grid-template-columns: 1fr 5em 5em;
}
.productlist.inventory.noProducts .list-grid {
  grid-template-columns: 1fr;
}
.productlist.summary .list-grid {
  grid-template-columns: min-content auto auto auto;
}
.productlist.summary.noProducts .list-grid,
.productlist.inventory.noProducts .list-grid {
  grid-template-columns: 1fr;
}
.purchaselist .list-grid {
  grid-template-columns: min-content 1fr max-content;
}
.quotelist .list-grid {
  grid-template-columns: min-content 1fr max-content;
}
.repairlist .list-grid {
  grid-template-columns: min-content 1fr max-content;
}
.reportlist .list-grid {
  grid-template-columns: auto auto;
}
.supplierlist .list-grid {
  grid-template-columns: min-content auto auto;
}
.textlist .list-grid,
.messagelist .list-grid,
.usersSettings .settingsListGrid,
.settingslist .list-grid {
  grid-template-columns: 100%;
}

.textlist .list-grid .panel_left,
.messagelist .list-grid .panel_left,
.settingslist .list-grid .panel_left {
  display: none;
}
.campaignlist .list-grid {
  grid-template-columns: 1fr max-content;
}

.newTextPanel {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 1em;
}
.messagelist .list-grid .panel_right div {
  margin-bottom: 0.5em;
}

.timesheetlist .list-grid {
  grid-template-columns: 1fr max-content max-content;
  row-gap: 1em;
}

.billinglist .list-grid {
  grid-template-columns: 1fr 40% max-content;
  row-gap: 1em;
}

.recurringlist.owing .list-grid,
.recurringlist.inactive .list-grid,
.recurringlist.active .list-grid {
  grid-template-columns: 1fr max-content max-content;
  row-gap: 1em;
}
.recurringlist.invoices .list-grid {
  grid-template-columns: max-content 1fr max-content;
  row-gap: 1em;
}

.recurringlist.plans .list-grid,
.billinglist.plans .list-grid {
  grid-template-columns: 1fr max-content max-content;
  row-gap: 1em;
}

.reportSettings {
  display: block;
}
.filterReport {
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-right: 0.1em;
}
.reportFilterType,
.reportEndDate,
.reportStartDate,
.timesheetEndDate,
.timesheetStartDate {
  display: grid;
  grid-template-columns: 7em auto;
  gap: 0.5em;
  margin-bottom: 1em;
}

.dateFilterType {
  display: grid;
  grid-template-columns: 7em auto;
  gap: 0.5em;
  margin: 0.5em 0;
}
.dateFilterButtons.controls-three-buttons {
  display: grid;
  grid-template-columns: auto;
}

.reportFilterType label,
.reportEndDate label,
.reportStartDate label,
.timesheetEndDate label,
.timesheetStartDate label {
  margin: auto auto auto 0;
}
.invoiceListTotalsGrid {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 0.5em;
}
.areaPaymentList .orderpaymentlist {
  display: grid;
  grid-template-columns: auto 25%;
}
.areaPaymentMethods {
  display: grid;
}
.areaProductSearchContainer,
.areaCustomerSearchContainer {
  display: grid;
  grid-template-columns: max-content min-content min-content;
}
.areaRepairItemCard {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(7, auto);
  gap: 0.25em;
}
.areaRepairItemInputContainer {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 0.75em;
}
.areaBaseDetailContainer.areaDetailRecurring,
.areaBaseDetailContainer.areaDetailBilling {
  display: grid;
  column-gap: 1em;
  grid-template-columns: 1fr;
}

.areaStoredPayments {
  display: grid;
  grid-template-columns: 1fr max-content max-content min-content min-content;
}
.areaCardVault {
  display: grid;
  grid-template-columns: 10% 10% 5% 10% 10% minmax(10%, max-content) 1fr;
}
.areaTransactions {
  display: grid;
  column-gap: 1em;
  grid-template-columns: max-content 3fr max-content 3fr max-content max-content max-content max-content 1fr;
}
.areaCustomFields {
  display: grid;
  column-gap: 1em;
  grid-template-columns: max-content 1fr;
}
.areaInvoices {
  display: grid;
  grid-template-columns: repeat(3, max-content) minmax(auto, max-content) repeat(7, max-content) 1fr repeat(3, max-content);
}
.areaStoredPaymentsHeader {
  display: grid;
  grid-template-columns: max-content 5%;
  column-gap: 1em;
}
.subscriptionCard,
.editSubscriptionDetails,
.newSubscriptionDetails,
.viewSubscriptionDetails {
  display: grid;
  grid-template-columns: 32% 1fr;
  column-gap: 1em;
  row-gap: 1em;
}

.span2,
.cancel,
.process,
.storecredit,
.storedpayment,
.other {
  grid-column: 1 / span 2;
}

.cardListContainer {
  display: grid;
  grid-template-columns: 1fr repeat(3, max-content) 1fr;
  column-gap: 1em;
  row-gap: 0.5em;
}

.contact-view-top-row {
  /* TRYING OUT FLEX INSTEAD OF GRID
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  justify-items: end; 
  */
  display: flex;
  justify-content: flex-end;
}
.control-buttons {
  display: grid;
  gap: 1em;
  justify-content: space-evenly;
}
.control-buttons-left {
  justify-content: flex-start;
}
.control-buttons-center {
  justify-content: center;
}
.controls-one-button {
  grid-template-columns: auto;
}
.controls-two-buttons {
  grid-template-columns: auto auto;
}
.controls-three-buttons {
  grid-template-columns: auto auto auto;
}

.errorBoundary {
  display: grid;
}
.folderListItems {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content 1fr max-content max-content max-content;
  column-gap: 1em;
  align-content: start;
}
.folderListItems.subscriptionInvoices {
  grid-template-columns: max-content max-content max-content max-content 1fr max-content 1fr max-content max-content;
}
.folderMatchListItems {
  display: grid;
  grid-template-columns: repeat(8, auto);
  column-gap: 1em;
  align-content: start;
}
.folderAgreementItems {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  column-gap: 1em;
  align-content: start;
}
.folderAuthorizationItems {
  display: grid;
  /* grid-template-columns: repeat(10, max-content) 1fr; */
  grid-template-columns: repeat(4, max-content) minmax(min-content, 28em) repeat(5, max-content) 1fr;
  column-gap: 1em;
  align-content: start;
}
.folderSubscriptionItems {
  display: grid;
  /* grid-template-columns: repeat(10, max-content) 1fr; */
  grid-template-columns: max-content 1fr repeat(9, max-content);
  column-gap: 1em;
  align-content: start;
}

.folderMessageListItems {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  column-gap: 1em;
  align-content: start;
}
.folderTextListItems {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
  align-content: start;
}

.folderShippingItems {
  display: grid;
  grid-template-columns: 15% 25% 1fr;
  column-gap: 3em;
  align-content: start;
}
.trackingInformation {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
  align-content: start;
}
.shippingDimensions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  align-content: start;
}

.reverbListItems {
  display: grid;
  grid-template-columns: 10% 1fr;
  column-gap: 1em;
}
.photoListItems {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 1em;
  padding: 1em;
}
.linkedListItems {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content;
  column-gap: 1em;
}
.input-container {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  column-gap: 0.25em;
}
.invoiceproductlist,
.quoteproductlist,
.repairproductlist,
.orderproductlist,
.purchaseproductlist {
  display: grid;
  grid-template-columns: auto 22% 28% min-content;
}
.returnproductlist {
  display: grid;
  grid-template-columns: auto max-content max-content;
}
.list-header-caret {
  grid-column: 1 / span 3;
}
.list-header1 {
  grid-column: 1 / span 2;
}

.customFieldsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  margin-top: -1em !important;
}
.nav-button-container {
  display: flex;
  gap: 1.6em;
}
.nav-icon {
  display: grid;
  grid-template-columns: auto;
}
.notesTab {
  display: grid;
  grid-template-columns: 10% 1fr;
  gap: 1em;
}
.orderlisttopcontrols {
  display: grid;
  grid-template-columns: auto auto auto auto 1fr;
}
.inventorylisttopcontrolsmanager {
  display: grid;
  grid-template-columns: auto auto 1fr;
}
.inventorylisttopcontrols {
  display: grid;
  grid-template-columns: auto 1fr;
}
.billinglisttopcontrols {
  display: grid;
  grid-template-columns: auto auto auto 1fr;
}
.recurringlisttopcontrols {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto 1fr;
}
.paginationContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1em;
}
.payment-action-button {
  display: grid;
  grid-template-columns: 40% 60%;
}
.payment-action-button-pp {
  display: grid;
  grid-template-columns: auto;
}
.payment-action-buttons {
  display: grid;
  grid-template-columns: auto;
}
.payment-icon-container {
  display: grid;
}
.payment-action-button-wrapper,
.paymentMethods {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1em;
}
.oneButtonCancel {
  display: grid;
  grid-template-columns: auto;
}
.payproductlist {
  display: grid;
  grid-template-columns: auto 25% 25%;
}
.processButtons {
  display: grid;
  grid-template-columns: auto auto;
}
.reportTable {
  display: grid;
  grid-template-columns: 1fr 8em;
  column-gap: 1em;
}
.reportTable6 {
  grid-template-columns: 3em 9em 5em 1em 1fr;
}
.reportTable10 {
  grid-template-columns: 3em 9em 5em 1em 1fr;
}

.reportTable11 {
  grid-template-columns: max-content 1fr max-content;
}
.reportTable12b,
.reportTable12 {
  grid-template-columns: 1fr max-content max-content;
}
.reportTable12b {
  row-gap: 0.75em;
}

.toprow {
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  justify-items: end;
  gap: 0;
}
.totalcontainer {
  display: grid;
  grid-template-columns: auto auto 35%;
}
.totalprice {
  display: grid;
  grid-template-columns: auto;
}
.mobile-header {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
}
.overlayDialog {
  display: grid;
  grid-template-columns: auto;
  row-gap: 0.75em;
}
.overlayList {
  display: grid;
  grid-template-columns: auto;
}

.product-suppliers {
  display: grid;
  grid-template-columns: 1fr 25% 10%;
  column-gap: 0.5em;
}

.checkboxGrid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1em;
  margin-bottom: 1em;
  text-align: left;
}
.inventoryStats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1em;
}
.fieldsToUpdate {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.fieldsToUpdate span {
  margin-left: 0.5em;
}

/* Grid Areas */

.areaRepairItemCard {
  grid-template-areas:
    "model"
    "description"
    "serial"
    "technician"
    "family"
    "status"
    "circle-icons";
}
.areaDetailCustomer {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaStoreCredit"
    "areaButtonControlsContainer";
}
.areaDetailCustomer.tabprospects {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaIdPanel"
    "areaProspectAdditionalData"
    "areaStoreCredit"
    "areaButtonControlsContainer";
}

.areaDetailProduct {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaProductCard"
    "areaProductManagerFields"
    "areaIdPanel"
    "areaButtonControlsContainer";
}
.areaDetailSupplier {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaButtonControlsContainer";
}
.areaDetailPay {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaProductList"
    "areaProductSearchContainer"
    "areaPaymentList"
    "areaAddProductContainer"
    "areaOrderTotalContainer"
    "areaButtonControlsContainer"
    "areaPaymentMethods";
}
.areaDetailQuote,
.areaDetailOrder,
.areaDetailReturn {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaProductList"
    "areaProductSearchContainer"
    "areaPaymentList"
    "areaAddProductContainer"
    "areaOrderTotalContainer"
    "areaButtonControlsContainer";
}
.areaDetailInvoice,
.areaDetailRepair {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaContactCardDeck"
    "areaRepairCardDeck"
    "areaProductList"
    "areaProductSearchContainer"
    "areaPaymentList"
    "areaAddProductContainer"
    "areaOrderTotalContainer"
    "areaButtonControlsContainer";
}

.areaDetailReport {
  grid-template-areas:
    "areaCloseButton"
    "areaHeader"
    "areaReportBody"
    "areaButtonControlsContainer";
}
.areaReportTimesheetExceptions {
  display: grid;
  grid-template-columns: 12em 8em 8em 8em;
  column-gap: 1em;
}

.areaDetailPurchase {
  grid-template-areas:
    "areaCloseButton"
    "areaIdPanel"
    "areaHeader"
    "areaContactCardDeck"
    "areaProductList"
    "areaProductSearchContainer"
    "areaAddProductContainer"
    "areaOrderTotalContainer"
    "areaButtonControlsContainer";
}

.areaDetailBilling {
  grid-template-areas:
    "areaCloseButton"
    "areaIdPanel"
    "areaHeader"
    "areaSubscriptionStatus"
    "areaContactCardDeck"
    "areaStoredPaymentsHeader"
    "areaStoredPayments"
    "areaOverdueTotal"
    "areaSubscriptions"
    "areaBillingPlanSearchContainer"
    "areaButtonControlsContainer";
}

.areaDetailRecurring {
  grid-template-areas:
    "areaCloseButton"
    "areaIdPanel"
    "areaHeader"
    "areaSubscriptionStatus"
    "areaContactCardDeck"
    "areaStoredPaymentsHeader"
    "areaStoredPayments"
    "areaOverdueTotal"
    "areaSubscriptions"
    "areaBillingPlanSearchContainer"
    "areaButtonControlsContainer";
}

.areaDetailRecurring.tabplans,
.areaDetailBilling.tabplans {
  grid-template-areas:
    "areaIdPanel"
    "areaHeader"
    "areaPlanSettings"
    "areaSubscribeContainer"
    "areaButtonControlsContainer";
}

.areaDetailCampaign {
  grid-template-areas:
    "areaCloseButton"
    "areaIdPanel"
    "areaHeader"
    "areaSwitches"
    "areaCampaignDetails"
    "areaCampaignLink"
    "areaButtonControlsContainer";
}

/****** Media Query for large desktops*********************************/
@media screen and (min-width: 1024px) {
  .toprow {
    grid-template-columns: 1fr max-content max-content max-content max-content;
    grid-column: 1 / span 3;
    justify-items: end;
    gap: 0.5em;
  }
  .campaignlist .list-grid {
    grid-template-columns: max-content 1fr max-content max-content;
  }

  .campaignGrid {
    display: grid;
    grid-template-columns: max-content 25%;
    column-gap: 1em;
  }
  .areaSwitchesCampaign {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    column-gap: 7em;
  }
  .orderTagGrid {
    display: grid;
    grid-template-columns: 1fr 17% max-content;
    column-gap: 1em;
  }

  .selectPlan {
    display: flex;
    justify-content: center;
    column-gap: 4em;
  }

  .faqLogo {
    grid-template-columns: max-content 1fr max-content;
  }

  .reportFilterType label,
  .reportEndDate label,
  .reportStartDate label,
  .timesheetEndDate label,
  .timesheetStartDate label {
    margin: auto 0 auto auto;
  }
  .reportEndDate,
  .reportStartDate,
  .timesheetEndDate,
  .timesheetStartDate {
    margin-bottom: 0;
  }
  .reportFilterType {
    margin-bottom: auto;
  }

  .dateFilterButtons.controls-three-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .dateFilterGrid {
    display: grid;
    grid-template-columns: 30% 1fr;
    column-gap: 1em;
    row-gap: 1em;
  }

  .faqContent {
    grid-template-columns: min-content 1fr;
  }

  .reportTable {
    grid-template-columns: max-content 8em;
  }
  .actionButtonStockOrder {
    display: grid;
    grid-template-columns: auto auto;
  }
  .customerlist .list-grid {
    grid-template-columns: max-content 1% 25% 15% 1fr 15% 15%;
  }
  .customerlist.prospectlist .list-grid {
    grid-template-columns: max-content 1% 1fr max-content 1fr max-content max-content 20% max-content;
  }
  .invoicelist .list-grid {
    grid-template-columns: 7% max-content 1fr 1fr 10% max-content;
  }
  .invoicelist.onlineunshipped .list-grid {
    grid-template-columns: 7% 7% 7% 7% 1fr 1fr 10% max-content;
  }
  .orderlist .list-grid {
    grid-template-columns: 7% 7% 25% 10% 20% 1fr max-content max-content max-content;
  }
  .orderlist.specials .list-grid {
    grid-template-columns: 10em 40em max-content max-content max-content;
  }
  .productlist .list-grid {
    grid-template-columns: min-content repeat(7, auto) minmax(12%, min-content);
  }
  .productlist.inventory .list-grid {
    grid-template-columns: minmax(9%, max-content) minmax(8%, max-content) 2fr 7em 7em 1em 1fr min-content;
  }
  .inventoryStats {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .productlist.summary .list-grid {
    grid-template-columns: min-content 1fr max-content 7em 1em 12em 1fr;
  }
  .purchaselist .list-grid {
    grid-template-columns: 7% 7% 25% 1fr max-content max-content;
  }

  .quotelist .list-grid {
    grid-template-columns: 7% 7% 25% 10% 1fr max-content max-content;
  }
  .repairlist .list-grid {
    grid-template-columns: 7% 7% 7% 25% 10% 1fr 10% 10% max-content;
  }
  .reportlist .list-grid {
    grid-template-columns: repeat(2, auto);
  }
  .supplierlist .list-grid {
    grid-template-columns: min-content auto min-content repeat(4, auto);
  }
  .textlist .list-grid,
  .messagelist .list-grid {
    grid-template-columns: 25% 74%;
    column-gap: 1em;
  }
  .importlist .list-grid {
    grid-template-columns: 50% 50%;
    column-gap: 1em;
  }
  .importlist .list-grid .panel_left {
    display: grid;
    grid-template-columns: max-content max-content 1fr;
    align-content: start;
  }
  .folderTextListItems .panel_left,
  .textlist .list-grid .panel_left,
  .messagelist .list-grid .panel_left {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-content: start;
  }
  .settingslist .list-grid {
    grid-template-columns: 15% 85%;
  }
  .settingslist .list-grid .panel_left {
    display: grid;
    grid-template-columns: 100%;
    align-content: start;
  }
  .generalSettings {
    display: grid;
    grid-template-columns: 35% 1fr;
    column-gap: 3em;
  }

  .timesheetlist .list-grid {
    grid-template-columns: 15% 1fr 25% 25% 20% 2%;
    row-gap: 1em;
  }

  .billinglist .list-grid {
    grid-template-columns: 1fr 15% 1fr max-content 10% 10% 10%;
    row-gap: 1em;
  }
  .billinglist.plans .list-grid {
    grid-template-columns: 8% 1fr 8% 8% 8% 10%;
    row-gap: 1em;
  }
  .recurringlist.owing .list-grid,
  .recurringlist.inactive .list-grid,
  .recurringlist.active .list-grid {
    grid-template-columns: max-content 1fr 10% 1fr 11% 10% 11% 11%;
    row-gap: 1em;
  }
  .recurringlist.invoices .list-grid {
    grid-template-columns: 7% max-content 1fr 2fr 10% max-content;
  }
  .recurringlist.plans .list-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr max-content;
    row-gap: 1em;
  }

  .recurringlist.cron .list-grid {
    grid-template-columns: repeat(11, auto);
    row-gap: 1em;
  }
  .recurringlist.cronlog .list-grid {
    grid-template-columns: repeat(7, auto);
    row-gap: 1em;
  }

  .reportSettings {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    gap: 1em;
  }
  .reportIncludeSubscriptions {
    padding-top: 0.15em;
  }
  .reportIncludeSubscriptions .switch-wrapper {
    padding: 0.3em 0.5em;
    margin: auto;
  }

  .reportEndDate,
  .reportStartDate,
  .timesheetEndDate,
  .timesheetStartDate {
    display: grid;
    grid-template-columns: 6em auto;
    gap: 0.5em;
    margin: 0;
  }

  .action-buttons .action-button-cell {
    display: grid;
  }

  .productManagerItem {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 1em;
  }

  .clientPage {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 2em;
  }
  .areaBaseDetailContainer {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 9% 15% 1fr 3% 20%;
  }
  .areaBaseDetailContainer.subscription {
    grid-template-columns: 20% 10% 1fr 10% 20%;
  }
  .areaBaseDetailContainer.tabprospects {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  }

  /* Setting Screen Override of areaBaseDetailContainer */
  .areaBaseDetailContainer.areaDetailSetting {
    display: grid;
    grid-template-columns: 1fr;
  }

  .areaBaseDetailContainer.areaDetailRecurring.tabplans,
  .areaBaseDetailContainer.areaDetailBilling.tabplans {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 10% 30% 1fr 3% 20%;
  }
  .areaBaseDetailContainer.areaDetailRecurring,
  .areaBaseDetailContainer.areaDetailBilling {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 17% 20% 1fr 15% 15%;
  }

  .areaStoredPayments {
    display: grid;
    grid-template-columns: 1fr 30% 15% min-content min-content;
  }
  .areaStoredPaymentsHeader {
    display: grid;
    grid-template-columns: max-content 5%;
    column-gap: 1em;
  }
  .paymentForm {
    display: grid;
  }

  .areaRecurringSubscriptions {
    /* display: grid; */
    grid-template-columns: max-content 1fr max-content 6% 8% 8% max-content max-content 10% max-content max-content;
    row-gap: 1em;
  }

  .areaSubscriptions {
    display: grid;
    grid-template-columns: 18% 8% 8% 8% 9.5% 9.5% 6% 10% 1fr max-content;
    row-gap: 1em;
  }

  .recurringSubscriptionCard,
  .subscriptionCard {
    display: grid;
    grid-template-columns: 50% 48%;
    column-gap: 3em;
  }
  .viewSubscriptionDetails,
  .editSubscriptionDetails,
  .newSubscriptionDetails {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: min-content;
    column-gap: 1em;
    row-gap: 1em;
    align-items: start;
  }

  .subscriptionCustomDetails {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-auto-rows: min-content;
    column-gap: 1em;
    align-items: start;
  }

  .areaSettings {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 2em;
    row-gap: 4em;
  }

  .repairGrid {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }

  .settingsListGrid {
    display: grid;
    column-gap: 1em;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 0.5em;
  }
  .usersSettings .settingsListGrid {
    grid-template-columns: max-content max-content max-content max-content max-content max-content max-content 1fr;
  }

  /* TODO What the hell is this??? 45.35%? */
  .areaDetailPay {
    grid-template-columns: 25% 25% 45.35% 1fr;
  }
  .areaDetailRepair {
    grid-template-columns: 9% 15% 1fr 3% 20%;
  }
  .areaDetailReport {
    grid-template-columns: 19% 19% 19% 19% 1fr;
  }
  .areaDetailDashboard {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    row-gap: 1em;
    align-items: flex-start;
  }
  .reportTable {
    display: grid;
    grid-template-columns: 18em 8em;
    column-gap: 1em;
  }
  .reportTable3 {
    grid-template-columns: 15em 8em 1fr;
  }
  .reportTable6 {
    grid-template-columns: repeat(6, max-content) 1fr;
  }
  .reportTable10 {
    grid-template-columns: repeat(9, max-content) 1fr;
  }
  .reportTable11 {
    grid-template-columns: repeat(10, max-content) 1fr;
  }
  .reportTable12 {
    grid-template-columns: repeat(5, max-content) 3fr repeat(7, max-content) min-content;
  }
  .reportTable12b {
    grid-template-columns: max-content minmax(min-content, 15%) repeat(9, max-content) 1fr;
  }

  .areaReportTimesheetExceptions {
    display: grid;
    grid-template-columns: 12em 12em 12em 8em;
    column-gap: 1em;
  }
  /* .areaDetailSetting {
    display: grid;
    grid-template-columns: 28% 70%;
  } */
  .recurringplanssearchresults,
  .billingplanssearchresults {
    display: grid;
    column-gap: 1em;
    grid-template-columns: max-content max-content max-content max-content;
  }

  .fiftyfifty {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 1em;
  }
  .areaButtonControlsContainer .controls-two-buttons,
  .areaButtonControlsContainer .controls-three-buttons {
    gap: 20em;
  }
  .areaContactSearchResults {
    grid-template-columns: max-content max-content max-content;
  }
  .areaDetailPay .totalcontainer {
    grid-template-columns: 1fr max-content 15%;
  }
  .areaPaymentList .orderpaymentlist {
    grid-template-columns: 1fr 15%;
  }
  .areaProductList .invoiceproductlist {
    grid-template-columns: 1fr 10% 10% 15% 10% 10% 10% max-content;
  }
  .areaProductList .orderproductlist {
    grid-template-columns: 1fr 10% 10% 10% 10% 10% max-content max-content;
  }
  .areaProductList .purchaseproductlist {
    grid-template-columns: 1fr max-content 1fr 7em 7em 7em 7em 7em max-content max-content;
  }
  .areaProductList .returnproductlist {
    grid-template-columns: 1fr repeat(8, 10%);
  }
  .areaProductList .quoteproductlist {
    grid-template-columns: 1fr 10% 10% 10% 10% 10% max-content max-content;
  }
  .areaProductList .repairproductlist {
    grid-template-columns: 1fr 10% 10% 10% 10% 10% 10% max-content max-content;
  }
  .areaRepairCardDeck {
    display: grid;
    grid-template-columns: 100%;
  }
  .areaRepairItemCard {
    grid-template-columns: 50% 40% min-content min-content;
    grid-template-rows: auto auto auto 1fr;
  }
  .column1 {
    grid-row: 2;
  }
  .column2 {
    grid-row: 2;
  }
  .column3 {
    grid-row: 2;
  }
  .contact-details {
    display: grid;
    grid-template-columns: max-content max-content;
  }
  .creditcarddesktop {
    display: grid;
    grid-template-columns: 1fr 20% 20%;
    column-gap: 1em;
    justify-items: center;
  }
  .listControlsContainer {
    display: grid;
    grid-template-columns: auto 1fr 20em;
    column-gap: 2em;
    row-gap: 0.5em;
  }
  .listControlButtons {
    display: flex;
    column-gap: 1em;
    row-gap: 0;
  }

  .nav-bar-container-dev,
  .nav-bar-container {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 1.6em;
  }
  .orderproductsearchresults {
    grid-template-columns: max-content max-content auto;
  }
  .paginationContainer {
    grid-template-columns: 1fr auto auto auto;
  }
  .paymentstorecredit,
  .paymentother,
  .paymentpaypal,
  .paymentcheck,
  .paymentcash,
  .paymentgiftcard {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    gap: 1em;
  }
  .payproductlist {
    grid-template-columns: auto 15% 15% 15%;
  }

  .product-details {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 20% 20% 20%;
  }

  .longdescription {
    grid-row: 2 / span 4;
  }
  .supplier-card {
    grid-column: 2 / span 3;
  }

  .totalcontainer {
    grid-template-columns: auto max-content 15%;
  }
  .totalprice {
    display: grid;
    grid-template-columns: auto;
    justify-items: end;
  }

  .product-suppliers {
    grid-template-columns: 1fr 25% 15% 8%;
  }

  .areaDetailPay {
    grid-template-areas:
      "areaHeader areaIdPanel areaPaymentMethods areaCloseButton"
      "areaContactCardDeck areaIdPanel areaPaymentMethods ."
      "areaContactCardDeck  . areaPaymentMethods ."
      "areaProductList areaProductList areaPaymentMethods ."
      "areaPaymentList areaPaymentList areaPaymentMethods ."
      "areaOrderTotalContainer areaOrderTotalContainer areaPaymentMethods ."
      ". . areaPaymentMethods ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer";
  }
  .areaDetailCustomer {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      ". . . areaIdPanel areaIdPanel"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaIdPanel areaIdPanel"
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaOrderTotalContainer";
  }
  .areaDetailCustomer.tabprospects {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaContactCardDeck . areaProspectAdditionalData areaIdPanel areaIdPanel"
      ". . areaProspectAdditionalData areaIdPanel areaIdPanel"
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaOrderTotalContainer";
  }
  .areaDetailProduct {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaProductCard areaProductCard areaProductCard areaIdPanel areaIdPanel"
      "areaProductCard areaProductCard areaProductCard areaProductManagerFields areaProductManagerFields"
      "areaProductCard areaProductCard areaProductCard . ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaOrderTotalContainer";
  }
  .areaDetailSupplier {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaIdPanel areaIdPanel"
      ". . . areaIdPanel areaIdPanel"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer"
      "areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer"
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaOrderTotalContainer";
  }

  .areaDetailQuote,
  .areaDetailOrder,
  .areaDetailReturn {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaIdPanel areaIdPanel"
      ". . . areaIdPanel areaIdPanel"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      "areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer";
  }

  .areaDetailInvoice,
  .areaDetailRepair {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaContactCardDeck areaContactCardDeck areaRepairCardDeck areaIdPanel areaIdPanel"
      ". . . areaIdPanel areaIdPanel"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer"
      "areaPaymentList areaPaymentList areaPaymentList areaPaymentList areaPaymentList"
      "areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer";
  }

  .areaDetailReport {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaReportBody areaReportBody areaReportBody areaReportBody areaReportBody"
      "areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer";
  }

  .areaDetailPurchase {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaIdPanel areaIdPanel"
      ". . . areaIdPanel areaIdPanel"
      "areaProductList areaProductList areaProductList areaProductList areaProductList"
      "areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer areaProductSearchContainer"
      "areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer areaAddProductContainer"
      ". . areaOrderTotalContainer areaOrderTotalContainer areaOrderTotalContainer"
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer . ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer";
  }

  /* This view has a separate rendering function in BillingPlan.jsx */
  .areaDetailRecurring.tabplans,
  .areaDetailBilling.tabplans {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaHeader areaIdPanel"
      "areaPlanSettings areaPlanSettings . . ."
      "areaSubscribeContainer areaButtonControlsContainer areaButtonControlsContainer . ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer";
  }
  .areaDetailBilling {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaHeader areaIdPanel"
      "areaHeader areaHeader areaHeader areaHeader areaIdPanel"
      "areaSubscriptionStatus . . areaStoredPaymentsHeader areaStoredPaymentsHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck . ."
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaOverdueTotal areaOverdueTotal"
      "areaSubscriptions areaSubscriptions areaSubscriptions areaSubscriptions areaSubscriptions"
      "areaBillingPlanSearchContainer . . . ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      ". areaButtonControlsContainer areaButtonControlsContainer . .";
  }

  .areaDetailRecurring {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaHeader areaIdPanel"
      "areaHeader areaHeader areaHeader areaHeader areaIdPanel"
      "areaSubscriptionStatus . . areaStoredPaymentsHeader areaStoredPaymentsHeader"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaStoredPayments areaStoredPayments"
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck . ."
      "areaContactCardDeck areaContactCardDeck areaContactCardDeck areaOverdueTotal areaOverdueTotal"
      "areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions areaRecurringSubscriptions"
      "areaBillingPlanSearchContainer . . . ."
      "areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer areaFoldersContainer"
      ". areaButtonControlsContainer areaButtonControlsContainer . .";
  }

  .areaDetailCampaign {
    grid-template-areas:
      "areaHeader areaHeader areaHeader areaIdPanel areaIdPanel"
      "areaSwitches areaSwitches areaSwitches areaIdPanel areaIdPanel"
      "areaSwitches areaSwitches areaSwitches areaIdPanel areaIdPanel"
      "areaSwitches areaSwitches areaSwitches areaCampaignLink areaCampaignLink"
      "areaCampaignDetails areaCampaignDetails areaCampaignDetails areaCampaignDetails areaCampaignDetails"
      "areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer areaButtonControlsContainer";
  }

  .areaRepairItemCard {
    grid-template-areas:
      "model serial repairdelete repairadd"
      "description technician repairdelete repairadd"
      "description family repairdelete repairadd"
      "description status repairdelete repairadd";
  }
}
